import { IVideoLegacyService, IGetVideoURL } from '../../VideoLegacy.types';
import { buildUrl } from '../utils';

const getUrl: IGetVideoURL = ({
  videoId,
  autoplay,
  isPlayingAllowed = true,
  loop,
  showInfo,
}) => {
  const baseUrl = `//player.vimeo.com/video/${videoId}`;
  const shouldAutoplay = isPlayingAllowed && autoplay;

  const params = {
    autoplay: shouldAutoplay,
    muted: shouldAutoplay,
    loop,
    byline: showInfo,
    portrait: showInfo,
    title: showInfo,
  };

  return buildUrl(baseUrl, params);
};

const title = 'External Vimeo';

export default { getUrl, title } as IVideoLegacyService;
