export function buildUrl(
  url: string,
  extraParams: { [key: string]: any },
): string {
  const parsedUrl = new URL('https:' + url);

  Object.keys(extraParams).forEach(key =>
    parsedUrl.searchParams.append(key, extraParams[key]),
  );

  return parsedUrl.href;
}
