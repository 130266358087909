import {
  IVideoLegacyProps,
  IVideoLegacyService,
} from '../../VideoLegacy.types';
import youtube from './youtube';
import facebook from './facebook';
import dailyMotion from './dailyMotion';
import vimeo from './vimeo';

export default {
  YOUTUBE: youtube,
  FACEBOOK: facebook,
  DAILYMOTION: dailyMotion,
  VIMEO: vimeo,
} as Record<IVideoLegacyProps['videoType'], IVideoLegacyService>;
