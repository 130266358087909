import { IVideoLegacyService, IGetVideoURL } from '../../VideoLegacy.types';
import { buildUrl } from '../utils';

const getUrl: IGetVideoURL = ({
  videoId,
  autoplay,
  isPlayingAllowed = true,
  showControls,
  showInfo,
}) => {
  const baseUrl = `//www.dailymotion.com/embed/video/${videoId}`;
  const shouldAutoplay = isPlayingAllowed && autoplay;

  const params = {
    autoplay: shouldAutoplay,
    mute: shouldAutoplay,
    'ui-start-screen-info': showInfo ? '1' : '0',
    controls: showControls === 'temp_show' ? '1' : '0',
    'sharing-enable': '0',
    'ui-logo': '0',
  };

  return buildUrl(baseUrl, params);
};

const title = 'External Daily Motion';

export default { getUrl, title } as IVideoLegacyService;
