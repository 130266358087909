import { IVideoLegacyService, IGetVideoURL } from '../../VideoLegacy.types';
import { buildUrl } from '../utils';

const getUrl: IGetVideoURL = ({
  videoId,
  autoplay,
  isPlayingAllowed = true,
  youtubeConfig,
  showControls,
  loop,
  showInfo,
}) => {
  const { lightTheme = true, enablejsapi = 0 } = youtubeConfig || {};

  const baseUrl = `//www.youtube.com/embed/${videoId}`;
  const shouldAutoplay = isPlayingAllowed && autoplay;

  const params: Record<string, string | number> = {
    wmode: 'transparent',
    autoplay: shouldAutoplay ? '1' : '0',
    mute: shouldAutoplay ? '1' : '0',
    theme: lightTheme ? 'light' : 'dark',
    controls: showControls !== 'always_hide' ? '1' : '0',
    autohide: showControls === 'temp_show' ? '1' : '0',
    loop: loop ? '1' : '0',
    showinfo: showInfo ? '1' : '0',
    rel: '0',
    enablejsapi,
  };
  if (loop) {
    params.playlist = videoId;
  }

  return buildUrl(baseUrl, params);
};

const title = 'External YouTube';

export default { getUrl, title } as IVideoLegacyService;
