import VideoComponent from '@wix/thunderbolt-elements/src/components/VideoLegacy/viewer/VideoLegacy';


const Video = {
  component: VideoComponent
};


export const components = {
  ['Video']: Video
};


// temporary export
export const version = "1.0.0"
